import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Card, CardBody, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Images
import task_card_calendar from "../../assets/images/task-card-calendar.svg";
import Avatar from "../../components/Avatar";
import { getFirstWord } from '../../Utils/helper';
import Api from "../../Utils/API";
import { Api as ApiImage } from '../../Utils/consts';
import userIcon from "../../assets/images/userIcon.png";
import clockIcon from "../../assets/images/clockIcon.svg";
import ScroolUser from '../../components/ScroolUser';
import ConfirmDelete from '../../components/ConfirmDelete';
import Swal from 'sweetalert2';
import NumberPickerData from '../../components/NumberPickerData';
import ReactTooltip from 'react-tooltip';

const calendarFormatOptions = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'Do MMM',
    lastDay: '[Yesterday]',
    lastWeek: 'Do MMM',
    sameElse: 'Do MMM'
}
const CardTaskBox = React.memo(props => {
    const [state, setState] = useState({
        psOpen: false,
        qaOpen: false,
        loadPrimaryAssignee: false,
        loadQAAssignee: false,
        selectedProject: 0,
        coders: props.coders,
        primaryAdetails: '',
        QAdetails: '',
        preloadCard: false,
        timeOpen: false,
        hr: '',
        min: '',
        actualTimeHr: '',
        actualTimeMin: '',
        estimateTimeHr: '',
        estimateTimeMin: '',
        actualTimeAction: 1,
    });

    useEffect(() => {
        let actual_time_used = props.data && props.data['actual_time_used'] ? props.data['actual_time_used'] : '',
            estimated_time = props.data && props.data['estimated_time'] ? props.data['estimated_time'] : '';

         // convert 0:0 to 00:00 actual time format
        let actual_time_hours = actual_time_used.split(':')[0], 
            actual_time_minutes = actual_time_used.split(':')[1];
            actual_time_hours = ("0" + actual_time_hours).slice(-2);
            actual_time_minutes = ("0" + actual_time_minutes).slice(-2);
     
        // convert 0:0 to 00:00 estimated time format
        let estimate_hours = estimated_time.split(':')[0], 
            estimate_minutes = estimated_time.split(':')[1];
            estimate_hours = ("0" + estimate_hours).slice(-2);
            estimate_minutes = ("0" + estimate_minutes).slice(-2);
        
        setState({
            ...state, 
            primaryAdetails: props.data.primary_assignee? props.data.primary_assignee: '',
            QAdetails: props.data.qa_assignee ? props.data.qa_assignee : '',
            preloadCard: props.cardID == props.data['id'] && props.preloadCard,
            actualTimeHr: actual_time_hours,
            actualTimeMin: actual_time_minutes,
            estimateTimeHr: estimate_hours,
            estimateTimeMin: estimate_minutes,
        })
    }, [props]);

    function ButtonUserOnly(props) {
        return (
            <span className="primary-assignee-div">
                <span className="close-avatar" onClick={props.actionDelete} >
                    <button className="btn-cirle" > 
                        {props.load ? 
                                <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> 
                            :   <i className=" fa fa-times" style={{ fontSize: 10 }} ></i>  }
                    </button>
                </span>
                <span onClick={props.action} >
                    <Avatar
                        name={` ${getFirstWord(props.details.firstname)} ${getFirstWord(props.details.surname)} `}
                        round={true}
                        size="32"
                        color="#f44336"
                        image={props.details.avatar ? ApiImage + props.details.avatar : ''}
                        className="btn-cirle-avatar"
                    />
                </span>
            </span>
        )
    }

    function ButtonUser(props) {
        return (
            <button className="btn btn-cirle-dash" onClick={props.action}>
                { props.load && <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>  }
                <img src={userIcon}/>
            </button>
        )
    }
    
    const selectPS = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.body.classList.add('primary-assignee-show');
        document.querySelector('.primary-assignee-content').classList.remove('toogle-hide');
        setState({ ...state, psOpen: true, qaOpen: false });
    }

    const selectQA = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        document.body.classList.add('primary-qa-show');
        document.querySelector('.primary-qa-content').classList.remove('toogle-hide');
        setState({ ...state, qaOpen: true, psOpen: false });
    }

    const primaryAssignee = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ ...state, loadPrimaryAssignee: true, psOpen: false });

        let coders = state.coders;
        let selectedData = item;
        var indexData = coders.indexOf(selectedData);
        let newObject = { index: indexData, ...selectedData };
        let selectedCoders = state.primaryAdetails;

        let params = {
            task_id: props.data.id,
            primary_assignee: item.id,
        }

        if(state.primaryAdetails && state.primaryAdetails.id == selectedData.id){
            setState({ ...state, loadPrimaryAssignee: false })
            return deletePrimaryAssignee(e);
        }

        Api.primaryUpdate(params).then(result => {
            setState({ ...state, loadPrimaryAssignee: false })
            if (state.primaryAdetails) {
                // REMOVE SELECTED ASSIGNEE FROM LIST
                let lastIndex = coders.length - 1;  // get the last index of coders
                let indexToAdd = selectedCoders.index;  // get index of record to add

                // ADD PREVIOUSLY SELECTED ASSIGNEE BACK TO THE LIST
                if (indexToAdd >= lastIndex)
                    if (selectedCoders.id > coders[lastIndex].id)
                        coders.push(selectedCoders);
                setState({ ...state, primaryAdetails: newObject });
            } else {
                setState({ ...state, primaryAdetails: newObject });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const deletePrimaryAssignee = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ ...state, loadPrimaryAssignee: true });

        let params = {
            task_id: props.data.id,
        }

        Api.removePrimary(params).then(result => {
            setState({...state, primaryAdetails: '', loadPrimaryAssignee: false});
        }).catch(error => {
            console.log(error);
        });
    }

    const deleteQAAssignee = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ ...state, loadQAAssignee: true });
        
        let params = {
            task_id: props.data.id,
        }

        Api.removeQA(params).then(result => {
            setState({ ...state, QAdetails: '', loadQAAssignee: false });
        }).catch(error => {
            console.log(error);
        });
    }

    const qaAssignee = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ ...state, loadQAAssignee: true, qaOpen: false });

        let coders = state.coders;
        let selectedData = item;
        var indexData = coders.indexOf(selectedData);
        let newObject = { index: indexData, ...selectedData };
        let selectedCoders = state.QAdetails;
        let params = {
            task_id: props.data.id,
            qa_assignee: item.id,
        }

        if(state.QAdetails && state.QAdetails.id == selectedData.id){
            setState({ ...state, loadQAAssignee: false })
            return deleteQAAssignee(e);
        }

        Api.qaUpdate(params).then(result => {
            setState({ ...state, loadQAAssignee: false });
            if (state.QAdetails) {
                // REMOVE SELECTED ASSIGNEE FROM LIST
                let lastIndex = coders.length - 1;  // get the last index of coders
                let indexToAdd = selectedCoders.index;  // get index of record to add
    
                if (indexToAdd >= lastIndex)
                    if (selectedCoders.id > coders[lastIndex].id)
                        coders.push(selectedCoders);
                setState({ ...state, qaOpen: false, QAdetails: newObject });
            } else {
                setState({ ...state, qaOpen: false, QAdetails: newObject });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const closeDropdown = () => {
        setState({ ...state, qaOpen: false, psOpen: false });
    }
    
    const editActual = (e) => {
        // e.preventDefault();
        // e.stopPropagation();
        // document.body.classList.add('actual-time-show');
        // document.querySelector('.timer-picker-div').classList.remove('toogle-hide');
        // setState({ ...state, hr: state.actualTimeHr, min: state.actualTimeMin, timeOpen: true, actualTimeAction: 1, });
    }

    const editEstimate = (e) => {
        // e.preventDefault()
        // e.stopPropagation();
        // document.body.classList.add('actual-time-show');
        // document.querySelector('.timer-picker-div').classList.remove('toogle-hide');
        // setState({ ...state, hr: state.estimateTimeHr, min: state.estimateTimeMin, timeOpen: true, actualTimeAction: 2, });
    }

    function openForm(e) {
        props.requestDetails(props.data) 
    }

    function confirmDeleteRequest(){
        let confirm_details = { page: 'request' }

        ConfirmDelete(confirm_details).then(res => {
            if (res.isConfirmed)
                deleteRequest()
        })
    }

    function deleteRequest(){
        let params = {
            task_id: props.data.id
        }

        Api.deleteRequest(params).then(result => {
            Swal.fire( 'Deleted!', 'Request has been deleted.', 'success' )
            props.updateSection(props.data);
        }).catch(error => {
            console.log(error);
        });
    }

    function updateTime(e) {
        const params = {
            task_id: state.details.id,
            actual_time: e.hr + ':' + e.min,        // for actual time parameter
            estimateTime: e.hr + ':' + e.min,       // for estimate time parameter
        }

        e.hr = ("0" + e.hr).slice(-2);
        e.min = ("0" + e.min).slice(-2);

        if (e.type === 1) return updateTimeAPI(e, params);         // update time used
        else return updateEstimateAPI(e, params);                  // update estimate time
    }

    const updateTimeAPI = (e, params) => {
        Api.updateTimeUsed(params).then(result => {
            setState({ ...state, isSaveTime: false, timeOpen:false, actualTimeHr: e.hr, actualTimeMin: e.min, hr: e.hr, min: e.min });
            props.updateList();
        }).catch(error => {
            setState({ ...state, isSaveTime: false, timeOpen: false });
            console.log(error);
        });
    }

    const updateEstimateAPI = (e, params) => {
        Api.updateEstimateUsed(params).then(result => {
            setState({ ...state, isSaveTime: false, timeOpen: false, estimateTimeHr: e.hr, estimateTimeMin: e.min, hr: e.hr, min: e.min });
            props.updateList();
        }).catch(error => {
            setState({ ...state, isSaveTime: false, timeOpen: false });
            console.log(error);
        });
    }

    const handleTimePickerChange = (e) => {
        e.stopPropagation();
    }

    const dueDate = props.data['due_date'] ? moment(props.data['due_date']).calendar(null, calendarFormatOptions) : '--/--';
    let dueDateStyle = dueDate == 'Tomorrow' || dueDate == 'Today' ? dueDate : 'Date';
    if (moment(props.data['due_date']).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))
        dueDateStyle = 'PastDue';

    return (
        <React.Fragment>
            {props.data &&
                <Card className="task-card">
                    <CardBody className={`board-width card-due-${dueDateStyle}`} onClick={(e) => openForm(e)} >
                        {state.preloadCard ? 
                            <div className="task-card-preloader">
                                <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> 
                                <p>Updating Task Request. Please wait.</p>
                            </div>
                            : 
                            <div>
                                <UncontrolledDropdown className="float-right task-dropdown" onClick={e => e.stopPropagation()}>
                                    <DropdownToggle className="arrow-none" tag="i">
                                        <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem className="dropdown-delete" onClick={confirmDeleteRequest} >
                                            <i className="fa fa-trash"></i>
                                            Delete Request
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <div className="float-right ml-2 right-badges">
                                    <img className="task-card-calendar-ico" src={task_card_calendar}></img>
                                    <Badge className={`font-size-12 badge-due due-${dueDateStyle}`} pill>{dueDate}</Badge>
                                </div>
                                <div>
                                    <h5 className="font-size-15"><Link to="#" className="text-dark">{props.data['title'] ?? 'Request Title'}</Link></h5>
                                    <p className="text-muted mb-4">{props.data['company']}</p>
                                </div>

                                { state.primaryAdetails ? 
                                    <ButtonUserOnly action={selectPS} actionDelete={deletePrimaryAssignee} details={state.primaryAdetails} load={state.loadPrimaryAssignee}/>
                                    :
                                    <ButtonUser action={selectPS} load={state.loadPrimaryAssignee} />
                                }
                                <span className="primary-assignee-content" >
                                    { state.psOpen && <ScroolUser list={props.coders} closeDropdown={closeDropdown} action={primaryAssignee} page='board'/>}
                                </span>
                                
                                &nbsp;
                                { state.QAdetails ?
                                    <ButtonUserOnly action={selectQA} actionDelete={deleteQAAssignee} details={state.QAdetails} load={state.loadQAAssignee} />
                                    : 
                                    <ButtonUser action={selectQA} load={state.loadQAAssignee} />
                                }
                                <span className="primary-qa-content" >
                                    { state.qaOpen && <ScroolUser list={props.coders} closeDropdown={closeDropdown} action={qaAssignee} page='board'/> }
                                </span>

                                <div className="card-subtasks">
                                    Subtasks: <span style={{color: props.data.subtasks.length > 0 ? '#3680ed' : '#74788D'}}>{props.data.subtasks.length}</span>
                                </div>

                                <div className="card-actual-estimate">
                                    <img data-tip data-for={`actual-estimate-tooltip`} src={clockIcon} />
                                    <ReactTooltip 
                                        id={`actual-estimate-tooltip`} 
                                        effect="solid" 
                                        className="message-tooltip"
                                        place="left"
                                        // offset={{'top': -10, 'right': 28}} 
                                        // arrowColor="transparent"
                                        backgroundColor="#3F4667"
                                    > 
                                        Actual Time / Estimated Time
                                    </ReactTooltip>
                                    <span className="time-1 ml-1" onClick={editActual}> {state.actualTimeHr}:{state.actualTimeMin}</span>         {/* Actual Time */}
                                    <span className="time-2" onClick={editEstimate} > / {state.estimateTimeHr}:{state.estimateTimeMin}</span>     {/* Estimated Time */}
                                    {/* <div className="timer-picker-div" onClick={(e) => handleTimePickerChange(e)}>
                                        { state.timeOpen &&
                                            <NumberPickerData
                                                hr={state.hr}
                                                min={state.min}
                                                type={state.actualTimeAction}
                                                action={updateTime}
                                                loading={state.isSaveTime}
                                            />
                                        }
                                    </div> */}
                                </div>

                                <div className="text-left date">
                                    {moment(props.data['date']).format(' DD MMM, YYYY ')}
                                </div>
                            </div>
                        }
                        </CardBody>
                </Card>
            }
        </React.Fragment>
    );
});

export default CardTaskBox;