import React from 'react';
// import { Link } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import iconDL from "../assets/images/icon-dl.png";
import closeIcon from "../assets/images/closeIcon.svg";
import iconPDF from "../assets/images/icon-pdf.png";
import { stringLimit } from '../Utils/helper';
// import { Api as ApiImage } from '../Utils/consts';

var fileSaver = require('file-saver');

export default function AttachmentFile(props) {
    const [state, setState] = React.useState(false);
    let list = props.data;
    let filename = list.filename ?? list.name;
    let typeData = filename.substr(filename.length - 3);;

    function formatBytes(bytes, decimals = 2) {
        bytes = bytes * 1000;
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const zoomImage = () => {
        setState(true);
    }

    const removeAttachment = () => {
        props.removeAttachment(list, props.index);
    }

    function AttachmentCard(props) {
        return (
            <div className="note-attachment-preview">
                <img onClick={zoomImage} className={props.type === "note" ? "attachment-img note-attachment" : "attachment-img"} alt={list.name ?? list.filename} src={list.path ?? URL.createObjectURL(list)} style={{cursor: 'pointer', objectFit: 'cover', height: props.itemHeight}} />
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="row-attachment attachment-list" style={{padding: "5px"}}>
                {
                    typeData === 'pdf' ?
                        (<div className="note-attachment-preview"><img src={iconPDF} style={props.itemHeight ? {height: props.itemHeight} : {}} /></div>)
                        :
                        (<AttachmentCard removeAttachment={props.removeAttachment} file={list} type={props.type} itemHeight="40px" />)
                }
                {
                    state ?
                        <Lightbox
                            mainSrc={list.path ?? URL.createObjectURL(list)}
                            onCloseRequest={() => setState(false)}
                        />
                        : ''
                }
                <div className="note-attachment-details">
                    <div className="column-2" style={{flex: 3}}>
                        <h5 style={{cursor: 'pointer'}}  onClick={zoomImage} className="font-size-14 mb-1" title={list.filename ?? list.name} >{stringLimit({ count: 10, title: list.filename ?? list.name })}</h5>
                        <small style={{cursor: 'pointer'}}  onClick={zoomImage}>{formatBytes(list.filesize ?? list.size ?? '')}</small>
                    </div>
                    <div className="column-3" style={{flex: 1}}>
                        <img style={{ cursor: 'pointer' }} src={closeIcon} title="Remove Attachment" link={list.path} onClick={removeAttachment} style={{width: "8px", height: "8px", cursor: "pointer"}} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


