
import React, { useState, useEffect } from 'react';
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import TeamAssignmentRow from './PropertyManagement/TeamAssignmentRow';

export const PropertyInformation = (props) => {

    const [state, setState] = React.useState({
        property_details: props.propertyDetails,
        credentials: props.propertyDetails.credentials,
        projectManagers: [],
        developers: [],
        qas: [],
        designers: [],
        pricing: null,
    });

    useEffect(() => {
        let property = props.propertyDetails;
        let propery_details = typeof property.details == 'string' ? JSON.parse(property.details) : property.details;

        // if credential details is fetched as string
        props.propertyDetails.credentials.forEach(credential => {
            if(typeof credential.details == 'string') {
                let credential_details = JSON.parse(credential.details);
                credential.details = credential_details;
            }
        });

        const assignees = props.propertyDetails.assignees;
        const projectManagers = [];
        const designers = [];
        const developers = [];
        const qas = [];

        if(assignees.length) {
            assignees.map((assignee) => {
                switch (assignee.type) {
                    case "project manager":
                        return projectManagers.push(assignee.user.id);
                    case "designer":
                        return designers.push(assignee.user.id);
                    case "qa":
                        return qas.push(assignee.user.id);
                    default:
                        return developers.push(assignee.user.id);
                }
            });
        }

        setState({
            projectManagers,
            designers,
            developers,
            qas,
            credentials: props.propertyDetails.credentials,
        });
    }, [JSON.stringify(props.propertyDetails)]);
    
    return (
        <React.Fragment>
            <div className="contact-form property-information-form">
                <div className="form-header" onClick={props.openPropertInfo} >
                    <div className="row-1">{props.propertyDetails.name}</div>
                    <div className="row-2">
                        <button className="btn btn-icon" onClick={props.minimize} > <img src={minimizeIcon} /> </button>
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    {/* Assignees */}
                    <div className="property-container" key={0} >
                        <div className="property-header">Team</div>
                        <div className="">
                            <TeamAssignmentRow
                                projectManagers={state.projectManagers}
                                developers={state.developers}
                                qas={state.qas}
                                designers={state.designers}
                                pricing={state.pricing}
                                viewOnly={true}
                            />
                        </div>
                        {/* <div className="row" style={{display: "flex", flexDirection: "row", marginRight: "12px"}}>
                            {state.property_details.assignees.map((assignee, i) => {
                                return (
                                    <DisabledButtonUserOnly firstname={assignee.user.firstname} surname={assignee.user.surname} />
                                );
                            })}
                        </div> */}
                    </div>

                    {/* Main Credentials/Details */}
                    <div className="property-container" key={1} >
                        <div className="property-header">{props.propertyDetails.type === "website" ? "Website" : (props.propertyDetails.type === "server" ? "Server" : "Environment")}</div>
                        {/* Credentials */}
                        { props.propertyDetails.hasOwnProperty('details') ?   //If object has property key = "username"
                            <div className="contact-group">
                                <div className="row-1">
                                    Credentials
                                </div>
                                <div className="row-2">
                                    <input className="form-control" disabled placeholder="No Credentials" value={JSON.parse(props.propertyDetails.details)?.web_credentials}/>
                                </div>
                            </div>
                        : null }
                    </div>

                    {state.credentials.map((property, i) => {
                        return (
                            <div className="property-container" key={i + 1} >
                                <div className="property-header">{property.name}</div>

                                {/* Host */}
                                { property.details.hasOwnProperty('host') ?   //If object has property key = "host"
                                    <div className="contact-group">
                                        <div className="row-1"> Host </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Host" value={property.details.host || ''}/>
                                        </div>
                                    </div>
                                : null }

                                {/* PEM KEY */}
                                { property.details.hasOwnProperty('pem') ?   //If object has property key = "username"
                                    <div className="contact-group">
                                        <div className="row-1">
                                            Pem Key
                                        </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Pem File" value={property.details.pem}/>
                                        </div>
                                    </div>
                                : null }

                                {/* IP ADDRESS */}
                                { property.details.hasOwnProperty("ip_address") ?   //If object has property key = "ip_address"
                                    <div className="contact-group">
                                        <div className="row-1">
                                            IP Address
                                        </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No IP Address" value={property.details.ip_address}/>
                                        </div>
                                    </div>
                                : null }

                                {/* Username */}
                                { property.details.hasOwnProperty('username') ?   //If object has property key = "username"
                                    <div className="contact-group">
                                        <div className="row-1"> Username </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Username" value={property.details.username || ''} />
                                        </div>
                                    </div>
                                : null }

                                {/* SSL REQUIRED */}
                                { property.details.hasOwnProperty('withSsl') ?   //If object has property key = "dbname"
                                    <div className="contact-group">
                                        <div className="row-1">
                                            SSL Required
                                        </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Username" value={property.details.withSsl == 1 ? "Yes" : "No"} />
                                        </div>
                                    </div>
                                : null }

                                {/* Port */}
                                { property.details.hasOwnProperty('port') ?   //If object has property key = "port"
                                    <div className="contact-group">
                                        <div className="row-1"> Port </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Port" value={property.details.port || ''}/>
                                        </div>
                                    </div>
                                : null }

                                {/* Password */}
                                { property.details.hasOwnProperty('password') ?   //If object has property key = "password"
                                    <div className="contact-group">
                                        <div className="row-1"> Password </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Password" value={property.details.password || ''}/>
                                        </div>
                                    </div>
                                : null }

                                {/* DATABASE NAME */}
                                { property.details.hasOwnProperty('dbname') ?   //If object has property key = "dbname"
                                    <div className="contact-group">
                                        <div className="row-1"> DB Name </div>
                                        <div className="row-2">
                                            <input className="form-control" disabled placeholder="No Database Name" value={property.details.dbname || ''}/>
                                        </div>
                                    </div>
                                : null }
                            </div>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyInformation;
