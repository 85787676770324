import React, { useRef, useEffect } from 'react';
import Avatar from "./Avatar";
import { Api } from '../Utils/consts';
import { getColor } from '../Utils/helper';

export default function ScroolUser(props) {  
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    console.log(props.list);

    function getFirstWord(name) {
        var firstWord = name.replace(/ .*/, '');
        return firstWord;
    }

    //If clicked outside the div of primary assignee's dropdown container
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(e) {
                // inside click
                if (wrapperRef.current.contains(e.target)) { console.log('wrpper contains e');return };

                // outside click 
                if(props.page == 'board' || props.page == 'inbox'){
                    props.closeDropdown();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <div className={`assignee-scroll ${props.page}`} ref={wrapperRef}>
            {props.list.map((item, i) =>
                <div className={`assignee-scroll-list ${props.page}`} onClick={(e) => props.action(item, e)} key={i}  >
                    <Avatar
                        name={` ${getFirstWord(item.firstname)} ${getFirstWord(item.surname)} `}
                        round={true}
                        size="30"
                        color={getColor(i)}
                        image={Api + item.avatar}
                    />
                    <span className={`ml-2 ${props.page == 'request-assignee-qa' ? 'mr-2' : ''}`} >{item.firstname} {item.surname}</span>
                </div>
            )}
        </div>
    )
}

