import React, { useState, useEffect } from 'react';
import { MDBDataTable } from "mdbreact";

const DataTable = (props) => {
    const [isRendered, setIsRendered] = useState(false);
    const [state, setState] = useState({});

    useEffect(() => {
        if(props.currentPage) {
            let currentPage = props.currentPage;

            if("undefined" === typeof(state.min) && currentPage >= state.min && currentPage <= state.max) {
                setState({
                    ...state,
                    currentPage: currentPage,
                    lastPage: props.lastPage,
                    perPage: props.perPage,
                    from: props.fromIndex,
                    to: props.toIndex,
                    nextPageUrl: props.nextPageUrl,
                    prevPageUrl: props.prevPageUrl,
                    firstPageUrl: props.firstPageUrl,
                    lastPageUrl: props.lastPageUrl,
                    path: props.path,
                    nEmails: props.nEmails,
                })
            } else {
                let min = state.min;

                if(currentPage % 8 !== 0 || min > currentPage) {
                    min = currentPage > 8 ? (Math.trunc(currentPage / 8) * 8 + (currentPage < min ? -7 : 1) ) : 1;
                }

                let temp = min + 7;
                let max = temp > props.lastPage ? props.lastPage : temp;

                setState({
                    ...state,
                    currentPage: currentPage,
                    lastPage: props.lastPage,
                    perPage: props.perPage,
                    from: props.fromIndex,
                    to: props.toIndex,
                    nextPageUrl: props.nextPageUrl,
                    prevPageUrl: props.prevPageUrl,
                    firstPageUrl: props.firstPageUrl,
                    lastPageUrl: props.lastPageUrl,
                    path: props.path,
                    nEmails: props.nEmails,
                    max,
                    min,
                })
            }
        }
    }, [props]);

    function handlePaginationClick(e) {
        let page = e.target.value;
        props.handlePageChange(page, `${state.path}?page=${page}`)
    }

    function handleNext() {
        let currentPage = state.currentPage;
        if(currentPage != state.lastPage) props.handlePageChange(++currentPage, state.nextPageUrl)
    }

    function handlePrevious() {
        let currentPage = state.currentPage;
        if(currentPage > 1) props.handlePageChange(--currentPage, state.prevPageUrl)
    }

    const paginationNumbers = () => {
        let pages = [];

        for(let i = state.min; i <= state.max; i++) {
            pages.push(<li key={`page-${i}`} data-test="page-item" class={state.currentPage == i ? 'active page-item' : 'page-item'}><button onClick={(e) => handlePaginationClick(e)} value={i} class="page-link page-link">{i}</button></li>);
        }

        return pages;
    }

    return (
        <React.Fragment>
            <MDBDataTable
                className="MyTable"
                sortable={false}
                searching={false}
                responsive
                data={props.data}
                entriesOptions={props.entriesOptions}
                entries={props.entries}
                displayEntries={false}
                hover
                disableRetreatAfterSorting={true}
                paging={props.paging ?? true}
            />

            {/* CUSTOM PAGINATION *NOTE: Currently for inbox page only */}
            {("undefined" !== typeof (props.paging) && !props.paging) && 
                <div className="row" style={{marginLeft: '12px', marginRight: '12px'}}>
                    <div data-test="datatable-info" style={{flex: 1, justifyContent: 'flex-start'}}>
                        <div className="dataTables_info" role="status" aria-live="polite">Showing {state.from} to {state.to} of {state.nEmails} entries</div>
                    </div>
                    <div data-test="datatable-pagination">
                        <div className="dataTables_paginate">
                            <ul data-test="pagination" className="pagination">
                                <li data-test="page-item" className={state.currentPage == 1 ? "disabled page-item" : "page-item"}>
                                    <button onClick={() => handlePrevious()} value="prev" aria-label="Previous" className="page-link page-link"><span>Previous</span></button>
                                </li>
                                {paginationNumbers()}
                                <li data-test="page-item" className={state.currentPage == state.lastPage ? "disabled page-item" : "page-item"}>
                                    <button onClick={() => handleNext()} value="next" aria-label="Next" className="page-link page-link"><span>Next</span></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default DataTable;