import React, { useEffect } from 'react';
import Api from "../Utils/API";
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import Select from "react-select";
import { customStyles, urlValidator, numberValidator } from '../Utils/helper';
import { handleErrors } from '../Utils/handleError';
import addCardIcon from "../assets/images/icon-new-card.png";
import Swal from 'sweetalert2';
import TeamAssignmentRow from './PropertyManagement/TeamAssignmentRow';

function WebHostDiv(props) {
    return (
        <React.Fragment>
            <h4 className="website-font mt-4">Web Host</h4>
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Username
                        </div>
                        <div className="row-2">
                            <input type="1" counter={props.i} name="username" className="form-control" placeholder="Enter Username" value={props.items.details.username || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            URL
                        </div>
                        <div className="row-2">
                            <input type="1" counter={props.i} name="url" className="form-control" placeholder="Enter URL" value={props.items.details.url || ''} onChange={props.handleChange} />
                            {props.items.details.urlError ? <label className="eror-fields">{props.items.details.urlError}</label> : ''}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Password
                        </div>
                        <div className="row-2">
                            <input type="1" counter={props.i} name="password" className="form-control" placeholder="Enter Password" value={props.items.details.password || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function WebSiteAdmin(props) {
    return (
        <React.Fragment>
            <h4 className="website-font mt-4">Website Admin</h4>
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Username
                        </div>
                        <div className="row-2">
                            <input type="1" counter={props.i} name="username" className="form-control" placeholder="Enter Username" value={props.items.details.username || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            URL
                        </div>
                        <div className="row-2">
                            <input type="1" counter={props.i} name="url" className="form-control" placeholder="Enter URL" value={props.items.details.url || ''} onChange={props.handleChange} />
                            {props.items.details.urlError ? <label className="eror-fields">{props.items.details.urlError}</label> : ''}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Password
                        </div>
                        <div className="row-2">
                            <input type="1" counter={props.i} name="password" className="form-control" placeholder="Enter Password" value={props.items.details.password || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function Sftp(props) {
    return (
        <React.Fragment>
            <h4 className="website-font mt-4">SFTP</h4>
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Username
                        </div>
                        <div className="row-2">
                            <input type="3" counter={props.i} name="username" className="form-control" placeholder="Enter Username" value={props.items.details.username || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            URL
                        </div>
                        <div className="row-2">
                            <input type="3" counter={props.i} name="url" className="form-control" placeholder="Enter URL" value={props.items.details.url || ''} onChange={props.handleChange} />
                            {props.items.details.urlError ? <label className="eror-fields">{props.items.details.urlError}</label> : ''}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Password
                        </div>
                        <div className="row-2">
                            <input type="3" counter={props.i} name="password" className="form-control" placeholder="Enter Password" value={props.items.details.password || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            Port
                        </div>
                        <div className="row-2">
                            <input type="number" min="0" onKeyDown={props.editPort} counter={props.i} name="port" className="form-control" placeholder="Enter Port" value={props.items.details.port || ''} onChange={(e) => props.updateCredentials(props.i, 'details', 'port', e.target.value)} />
                            {props.items.details.portError ? <label className="eror-fields">{props.items.details.portError}</label> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function Other(props) {
    return (
        <React.Fragment>
            <input type="5" counter={props.i} name="name" className="form-control other-text" placeholder="Enter Name" value={props.items.details.name || ''} onChange={props.handleChange} />
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Username
                        </div>
                        <div className="row-2">
                            <input type="4" counter={props.i} name="username" className="form-control" placeholder="Enter Username" value={props.items.details.username || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            URL
                        </div>
                        <div className="row-2">
                            <input type="4" counter={props.i} name="url" className="form-control" placeholder="Enter URL" value={props.items.details.url || ''} onChange={props.handleChange} />
                            {props.items.details.urlError ? <label className="eror-fields">{props.items.details.urlError}</label> : ''}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Password
                        </div>
                        <div className="row-2">
                            <input type="4" counter={props.i} name="password" className="form-control" placeholder="Enter Password" value={props.items.details.password || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            Port
                        </div>
                        <div className="row-2">
                            <input type="number" min="0" onKeyDown={props.editPort} counter={props.i} name="port" className="form-control" placeholder="Enter Port" value={props.items.details.port || ''} onChange={(e) => props.updateCredentials(props.i, 'details', 'port', e.target.value)} />
                            {props.items.details.portError ? <label className="eror-fields">{props.items.details.portError}</label> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function CreateUI(props) {
    return props.items.map((el, i) =>
        <div key={i} className="mb-4">
            {
                el.type === '1' ?
                    <WebHostDiv
                        handleChange={props.handleChange}
                        items={el}
                        i={i}
                    />
                    :
                    (el.type === '2' ?
                        <WebSiteAdmin
                            handleChange={props.handleChange}
                            items={el}
                            i={i}
                        />
                        :
                        (el.type === '3' ?
                            <Sftp
                                handleChange={props.handleChange}
                                items={el}
                                i={i}
                                editPort={props.editPort}
                                updateCredentials={props.updateCredentials}
                            />
                            :
                            (el.type === '4' ?
                                <Other
                                    handleChange={props.handleChange}
                                    items={el}
                                    i={i}
                                    editPort={props.editPort}
                                    updateCredentials={props.updateCredentials}
                                /> : ''
                            )
                        )
                    )
            }
        </div>
    )
}

export const AddWebsite = React.memo(props => {
    const [state, setState] = React.useState({
        company_id: props.companyId,
        selectedCompany: '',
        selectedContact: '',
        ismoreCredential: false,
        isSubmit: false,
        description: '',
        moreDetails: [],
        url: '',
        primary_contact: [],
        username: '',
        password: '',
        credentials: [
            {
                name: 'Web Host',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            },
            {
                name: 'Website Admin',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            },
            {
                name: 'SFTP',
                details: {
                    username: '',
                    url: '',
                    password: '',
                    port: '' 
                }
            },
        ],
        defaultCredentials: [
            {
                name: 'Web Host',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            },
            {
                name: 'Website Admin',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            },
            {
                name: 'SFTP',
                details: {
                    username: '',
                    url: '',
                    password: '',
                    port: '' 
                }
            },
        ],
        credentials_value: '',
        contacts: props.contacts,
        companies: props.companies || [],
        companyOptions: [],
        isMinimized: false,
        pricingMethods: [],
        projectManagers: [],
        developers: [],
        qas: [],
        designers: [],
        pricing: null,
    });

    useEffect(() => {
        if(state.companies.length > 0) {
            const companies = [];
            state.companies.map(company => {
                let company_details = {value: company.id, label: company.name}
                companies.push(company_details)
            });
            setState((prevState) => ({...prevState, companyOptions: companies}));
        }

        getPricingMethodOptions();
    }, []);

    function getPricingMethodOptions() {
        Api.getPricingMethodOptions().then(result => {
            setState((prevState) => ({
                ...prevState,
                pricingMethods: result.data
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    function updateCredentials(index, data, subdata, _value) {
        let temp = state.credentials;

        if (subdata != null) {
            if(subdata === 'port') {
                _value = _value.toString().replace('e', '');
                _value = _value.replace('-', '');
                _value = parseInt(_value);
            }

            temp[index][data][subdata] = _value;
        } else {
            temp[index][data] = _value;
        }

        setState((prevState) => ({ ...prevState, temp }));
    }

    function submitWebsite(event) {
        event.preventDefault();
        const { credentials } = state;

        // validate url using urlValidator(string: url, boolean(optional, defaults to false): isRequired)
        let urlError = urlValidator(state.url, true);

        // validate urls and ports for the website credentials (Website Admin, Web Host, SFTP)
        let hasErrors = false;
        for (let i = 0; i < credentials.length; i++) {
            if(credentials[i].details.url) {
                credentials[i].details.urlError = urlValidator(credentials[i].details.url);
                if(credentials[i].details.urlError) {
                    hasErrors = true;
                }
            }

            if(credentials[i].details.port) {
                credentials[i].details.portError = numberValidator(credentials[i].details.port);
                if(credentials[i].details.portError) {
                    hasErrors = true;
                }
            }
        }

        // check for url errors and display an alert message
        if(urlError || hasErrors) {
            setState({ ...state, isSubmit: false, urlError, credentials });
            return Swal.fire('Error', 'Please enter valid input!', 'error')
        }

        // validate company attribute
        if(state.companies.length > 0 && !state.selectedCompany) {
            setState({ ...state, isSubmit: false });
            return Swal.fire('Ooops!', 'Please select a company!', 'error')
        }

        // initialise params
        const params = {
            name: state.url,
            company_id: state.companies.length > 0 ? state.selectedCompany.value : state.company_id,
            primary_contact_id: state.primary_contact.value,
            type: 'website',
            details: {
                web_credentials: state.webCredentials,
                contact_id: state.selectedContact?.value,
            },
            credentials: state.credentials,
            pricing_id: state.pricing?.value,
            project_managers: state.projectManagers,
            developers: state.developers,
            qas: state.qas,
            designers: state.designers,
        }

        setState({ ...state, isSubmit: true });
        Api.addProperty(params).then(result => {
            params.id = result.data.id;
            params.primary_contact = result.data.primary_contact;
            const credentials = state.defaultCredentials;
            props.addProperty(params);
            setState((prevState) => ({
                ...prevState,
                credentials,
                ismoreCredential: false,
                description: '',
                moreDetails: [],
                url: '',
                primary_contact: [],
                username: '',
                password: '',
                isSubmit: false
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
            setState({ ...state, isSubmit: false });
        });
    }

    const moreDetails = (e) => {
        let type = e.target.getAttribute("type");
        let values = [...state.credentials];
        if (type === '1') {
            values.push({
                type: type,
                name: 'Website Admin',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            });
        }
        if (type === '2') {
            values.push({
                type: type,
                name: 'Web Host',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            });
        }

        if (type === '3') {
            values.push({
                type: type,
                name: 'SFTP',
                details: {
                    username: '',
                    password: '',
                    url: '',
                    port: ''
                }
            });
        }
        
        if (type === '4') {
            values.push({
                type: type,
                name: '',
                details: {
                    username: '',
                    password: '',
                    url: '',
                    port: ''
                }
            });
        }

        setState((prevState) => ({ ...prevState, credentials: values, ismoreCredential: false }));
    }

    function handleChange(event) {
        let type = event.target.getAttribute('type');
        let values = [...state.credentials];
        const attribute = event.target.getAttribute('name');

        values[event.target.getAttribute('counter')].details[attribute] = event.target.value;
        if (type === '5') values[event.target.getAttribute('counter')][attribute] = event.target.value;

        setState((prevState) => ({ ...prevState, credentials: values }));
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState((prevState) => ({ ...prevState, isMinimized: true}));
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState((prevState) => ({ ...prevState, isMinimized: false}));
    }

    function editPort(event) {
        if(event.key == 'e' || event.key == '-' || event.key == '+') {
            event.preventDefault();
            return false;
        }
    }

    const updateDesigners = (id, prev = null) => {
        const {designers} = state;
        let prevIndex = prev ? designers.indexOf(prev) : -1;

        if(designers.includes(id)) {
            // remove id from the array
            designers.splice(designers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the designers array
            if(prev && designers.includes(prev)) {
                // retrieve prev's index
                prevIndex = designers.indexOf(prev);
                // remove prev from the array
                designers.splice(prevIndex, 1, id);
            } else {
                designers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const updateDevelopers = (id, prev = null) => {
        const {developers} = state;
        let prevIndex = prev ? developers.indexOf(prev) : -1;

        if(developers.includes(id)) {
            // remove id from the array
            developers.splice(developers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the developers array
            if(prev && developers.includes(prev)) {
                // retrieve prev's index
                prevIndex = developers.indexOf(prev);
                // remove prev from the array
                developers.splice(prevIndex, 1, id);
            } else {
                developers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const updateProjectManagers = (id, prev = null) => {
        const {projectManagers} = state;
        let prevIndex = prev ? projectManagers.indexOf(prev) : -1;

        if(projectManagers.includes(id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the projectManagers array
            if(prev && projectManagers.includes(prev)) {
                // retrieve prev's index
                prevIndex = projectManagers.indexOf(prev);
                // remove prev from the array
                projectManagers.splice(prevIndex, 1, id);
            } else {
                projectManagers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const updateQas = (id, prev = null) => {
        const {qas} = state;
        let prevIndex = prev ? qas.indexOf(prev) : -1;

        if(qas.includes(id)) {
            // remove id from the array
            qas.splice(qas.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the qas array
            if(prev && qas.includes(prev)) {
                // retrieve prev's index
                prevIndex = qas.indexOf(prev);
                // remove prev from the array
                qas.splice(prevIndex, 1, id);
            } else {
                qas.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const removeDesigner = (assignee) => {
        const {designers} = state;
        if(designers.includes(assignee.id)) {
            // remove id from the array
            designers.splice(designers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const removeDeveloper = (assignee) => {
        const {developers} = state;
        if(developers.includes(assignee.id)) {
            // remove id from the array
            developers.splice(developers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const removeProjectManager = (assignee) => {
        const {projectManagers} = state;
        if(projectManagers.includes(assignee.id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const removeQa = (assignee) => {
        const {qas} = state;
        if(qas.includes(assignee.id)) {
            // remove id from the array
            qas.splice(qas.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const selectPricing = (e) => {
        setState((prevState) => ({ ...prevState, pricing: e }));
    }

    const selectCompany = (e) => {
        Api.getCompanyContacts(e?.value ?? 0).then(result => {
            setState((prevState) => ({
                ...prevState,
                selectedCompany: e,
                contactOptions: result.data
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    const selectContact = (e) => {
        setState((prevState) => ({ ...prevState, selectedContact: e }));
    }

    return (
        <React.Fragment>
            <div className="contact-form website-form">
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1">Add Website</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    <div style={{borderBottom: "1px solid #ECECF5", padding: "0px", paddingBottom: "22px"}}>
                        <h4 className="website-font mt-4">Team</h4>
                        <div className="row ml-2">
                            <div className="col-lg-6" style={{paddingLeft: 0}}>
                                <div className="contact-group" style={{borderBottom: 'none'}}>
                                    <TeamAssignmentRow
                                        projectManagers={state.projectManagers}
                                        developers={state.developers}
                                        qas={state.qas}
                                        designers={state.designers}
                                        pricing={state.pricing}
                                        updateProjectManagers={updateProjectManagers}
                                        updateDevelopers={updateDevelopers}
                                        updateQas={updateQas}
                                        updateDesigners={updateDesigners}
                                        removeProjectManager={removeProjectManager}
                                        removeDeveloper={removeDeveloper}
                                        removeQa={removeQa}
                                        removeDesigner={removeDesigner}
                                    />
                                </div>
                            </div>
                            {state.companies.length > 0 &&
                                <div className="col-lg-6">
                                    <div className="contact-group">
                                        <div className="row-1">
                                            Pricing Method
                                        </div>
                                        <div className="row-2">
                                            <Select
                                                className="form-select ml-1"
                                                styles={customStyles}
                                                value={state.pricing}
                                                onChange={(e) => selectPricing(e)}
                                                options={state.pricingMethods}
                                                placeholder="Select Method"
                                                noOptionsMessage={() => null}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                            {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <h4 className="website-font mt-4">Website</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    URL
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.url} onChange={(e) => { setState({ ...state, url: e.target.value, urlError: '' }) }} />
                                    {state.urlError ? <label className="eror-fields">{state.urlError}</label> : ''}
                                </div>
                            </div>
                        </div>

                        {state.companies.length > 0 &&
                            <div className="col-lg-6">
                                <div className="contact-group">
                                    <div className="row-1">
                                        Primary Contact
                                    </div>
                                    <div className="row-2">
                                        <Select
                                            className="form-select ml-1"
                                            styles={customStyles}
                                            value={state.selectedContact}
                                            onChange={(e) => selectContact(e)}
                                            options={state.contactOptions}
                                            placeholder="Select Contact"
                                            noOptionsMessage={() => null}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                    </div>
                                </div>
                            </div>
                        }

                        {state.companies.length > 0 &&
                            <div className="col-lg-6">
                                <div className="contact-group">
                                    <div className="row-1">
                                        Company
                                    </div>
                                    <div className="row-2">
                                        <Select
                                            className="form-select ml-1"
                                            styles={customStyles}
                                            value={state.selectedCompany}
                                            onChange={(e) => selectCompany(e)}
                                            options={state.companyOptions}
                                            placeholder="Select Company"
                                            noOptionsMessage={() => 'TEST'}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Credentials
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Credentials" value={state.webCredentials ?? ""} onChange={(e) => { setState({ ...state, webCredentials: e.target.value, webCredentialsError: '' }) }} />
                                    {state.webCredentialsError ? <label className="eror-fields">{state.webCredentialsError}</label> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="website-font mt-4">Website Admin</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Username
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Username" value={state.credentials[1].details.username} onChange={(e) => updateCredentials(1, 'details', 'username', e.target.value)} />
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1">
                                    URL
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.credentials[1].details.url} onChange={(e) => updateCredentials(1, 'details', 'url', e.target.value)} />
                                    {state.credentials[1].details.urlError ? <label className="eror-fields">{state.credentials[1].details.urlError}</label> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Password
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.credentials[1].details.password} onChange={(e) => updateCredentials(1, 'details', 'password', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="website-font mt-4">Web Host</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1"> Username </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Username" value={state.credentials[0].details.username} onChange={(e) => updateCredentials(0, 'details', 'username', e.target.value)} />
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1"> URL </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.credentials[0].details.url} onChange={(e) => updateCredentials(0, 'details', 'url', e.target.value)} />
                                    {state.credentials[0].details.urlError ? <label className="eror-fields">{state.credentials[0].details.urlError}</label> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1"> Password </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.credentials[0].details.password} onChange={(e) => updateCredentials(0, 'details', 'password', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className="website-font mt-4">SFTP</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1"> Username  </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Username" value={state.credentials[2].details.username} onChange={(e) => updateCredentials(2, 'details', 'username', e.target.value)} />
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1"> URL </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.credentials[2].details.url} onChange={(e) => updateCredentials(2, 'details', 'url', e.target.value)} />
                                    {state.credentials[2].details.urlError ? <label className="eror-fields">{state.credentials[2].details.urlError}</label> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1"> Password </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter Password" value={state.credentials[2].details.password} onChange={(e) => updateCredentials(2, 'details', 'password', e.target.value)} />
                                </div>
                            </div>
                            <div className="contact-group">
                                <div className="row-1"> Port </div>
                                <div className="row-2">
                                    <input name="port" counter={2} type="number" min="0" onKeyDown={editPort} className="form-control" placeholder="Enter Port" onChange={(e) => updateCredentials(2, 'details', 'port', e.target.value)} value={state.credentials[2].details.port} />
                                    {state.credentials[2].details.portError ? <label className="eror-fields">{state.credentials[2].details.portError}</label> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-400 mt-4">
                        <CreateUI
                            items={state.credentials}
                            companiesOptions={state.companiesOptions}
                            handleChange={handleChange}
                            updateCredentials={updateCredentials}
                            editPort={editPort}
                        />
                        { state.ismoreCredential &&
                            <div className="select-type-content">
                                <div className="select-type">
                                    <div className="select-type-list">
                                        <div className="select-type-title">
                                            Select Type
                                    </div>
                                        <ul>
                                            <li type={1} onClick={moreDetails}>Web Host</li>
                                            <li type={2} onClick={moreDetails}>Website Admin</li>
                                            <li type={3} onClick={moreDetails}>SFTP</li>
                                            <li type={4} onClick={moreDetails}>Other</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="new-card-div ripple" onClick={() => (setState({ ...state, ismoreCredential: !state.ismoreCredential }))} >
                            <img src={addCardIcon} />
                            <span>Add More Credentials</span>
                        </div>
                    </div>

                    <div className="form-action">
                        <button onClick={submitWebsite} className="btn btn-primary btn-large" disabled={state.isSubmit}>
                            { state.isSubmit && <i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i> }
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
});

export default AddWebsite;
  