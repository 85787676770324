import React, {useState, useEffect} from 'react';

import Board from "@lourenci/react-kanban";
import { Row, Col } from "reactstrap";
import CardTaskBox from "./card-task-box";
import RenderCardTitle from "./render-card-title";
import Api from "../../Utils/API";
import ConfirmDelete from '../../components/ConfirmDelete';
import SubmitQA from '../../components/PopupForms/SubmitQA';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getCookie } from '../../Utils/helper';
import SendEmail from '../../components/PopupForms/SendEmail';

const ReactSwal = withReactContent(Swal);
const logged_in_user = getCookie("userDetails");
let user_details = '';
if (logged_in_user) user_details = (JSON.parse(logged_in_user));

const ControlledBoard = (props) => {
    const [content, updateContent] = useState(props.board);
    let [report, setReport] = useState({});
    let [emails, setEmails] = useState({});
    const [confirmed, setConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [stateCard, setStateCard] = useState({
        preloadCard: false,
        cardID: 0
    });

    useEffect(() => {
        updateContent(props.board)
    },[props.board])

    function updateBoard(card, source, destination) {
        setStateCard({...stateCard, preloadCard: true, cardID: card.id});
        let columns = content.columns
        const sourceSection = props.columns.find(item => item.id == source.fromColumnId)                             // source section
        const destinationSection = props.columns.find(item => item.id == destination.toColumnId)                     // destination section
        card.content.status = {'id': destinationSection.id, 'title': destinationSection.title, preload: true};       // update card's status_id

        // remove card from source
        for(let i = 0; i < columns.length; i++) {
            if(columns[i].id === source.fromColumnId) {
                columns[i].cards.splice(source.fromPosition, 1);
                break;
            }
        }

        // add card to destination
        for(let i = 0; i < columns.length; i++) {
            if(columns[i].id === destination.toColumnId) {
                columns[i].cards.splice(destination.toPosition, 0, card);
                break;
            }
        }

        updateContent({columns})                                                    // update board content
        handleCardDrag(card, source, destination, sourceSection, columns)           // update task status via api
    }

    const revertBoardChanges = (card, source, destination, sourceSection, columns) => {
        card.content.status = {'id': sourceSection.id, 'title': sourceSection.title, preload: true};                  // update card's status_id

        // add card to source
        for(let i = 0; i < columns.length; i++) {
            if(columns[i].id === destination.toColumnId) {
                columns[i].cards.splice(destination.toPosition, 1);
                break;
            }
        }

         // remove card from destination
         for(let i = 0; i < columns.length; i++) {
            if(columns[i].id === source.fromColumnId) {
                columns[i].cards.splice(source.fromPosition, 0, card);
                break;
            }
        }

        updateContent({columns})
        setStateCard({...stateCard, preloadCard: false});
    }

    const handleReportDetails = (value) => {
        report = value;
    }

    const submitQAModal = (parameters, card, source, destination, sourceSection, columns) => {
        ReactSwal.fire({
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                content: 'popupForm-QA-content',
                popup: 'popupForm-modal',
                closeButton: 'popupForm-close'
            },
            html: <SubmitQA 
                    props={card} 
                    user_details={user_details} 
                    confirmButton={ReactSwal} 
                    report_details={(e) => report = e}
                    revertBoardChanges={() => revertBoardChanges(card, source, destination, sourceSection, columns)}
                  />
          }).then((res) => {
            if(res.isConfirmed){
                card.content.report = report;
            }
            return updateTaskStatus(parameters, card, source, destination, sourceSection, columns);
        })
    }

    const sendEmailModal = (parameters, card, source, destination, sourceSection, columns) => {
        ReactSwal.fire({
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                content: 'popupForm-Email-content',
                popup: 'popupForm-modal',
                closeButton: 'popupForm-close'
            },
            html: <SendEmail 
                    props={card} 
                    user_details={user_details} 
                    confirmButton={ReactSwal} 
                    email_details={(e) => emails = e}
                    revertBoardChanges={() => revertBoardChanges(card, source, destination, sourceSection, columns)}
                  />
          }).then((res) => {
            if(res.isConfirmed){
                parameters.emailsToSend = emails;
                return updateTaskStatus(parameters, card, source, destination, sourceSection, columns);
            }

            revertBoardChanges(card, source, destination, sourceSection, columns)
        })
    }

    const handleCardDrag = (card, source, destination, sourceSection, columns) => {
        const parameters = {
            task_id: card.id,
            source: JSON.stringify(source),
            destination: JSON.stringify(destination),
        }
        
        const source_details = columns.find(column => column.id === source.fromColumnId);
        const destination_details = columns.find(column => column.id === destination.toColumnId);

        /* MODAL POPUP - FORM TO SUBMIT FOR QA */
        if(source_details.title == 'In Progress' && destination_details.title == 'Quality Assurance')
            return submitQAModal(parameters, card, source, destination, sourceSection, columns);

        /* MODAL POPUP - FORM TO SEND TASK EMAIL */
        if((source_details.title == 'In Review' || source_details.title == 'On-Hold') && destination_details.title == 'Awaiting Client Approval')
            return sendEmailModal(parameters, card, source, destination, sourceSection, columns);

        return updateTaskStatus(parameters, card, source, destination, sourceSection, columns);
    }

    const updateTaskStatus = (parameters, card, source, destination, sourceSection, columns) => {
        Api.updateTaskStatus(parameters).then(result => {
            console.log(result.data);
            setStateCard({...stateCard, preloadCard: false});
        }).catch(error =>{
            console.log(error);
            // REVERT STATUS CHANGE
            revertBoardChanges(card, source, destination, sourceSection, columns)
        });
    }
    
    function updateBoardColumn(column, source, destination) {
        const parameters = {
            id: column.id,
            source: source.fromPosition,
            destination: destination.toPosition
        }

        setIsLoading(true);

        Api.updateStatusColumn(parameters).then(result => {
            props.updateBoard().finally(() => setIsLoading(false));
        }).catch(error =>{
            console.log(error);
            props.updateBoard().finally(() => setIsLoading(false));
        });
    }

    const handleColumnDrag = (card, source, destination, sourceSection, columns) => {
        const parameters = {
            task_id: card.id,
            source: JSON.stringify(source),
            destination: JSON.stringify(destination)
        }

        Api.updateTaskStatus(parameters).then(result => {
            setStateCard({...stateCard, preloadCard: false});

        }).catch(error =>{
            console.log(error);

            // REVERT STATUS CHANGE
            revertBoardChanges(card, source, destination, sourceSection, columns)
        });
    }

    const updateSection = (e) => {
        let columns = content.columns;
        const section = columns.find(item => item.id == e.status.id);           // find section from selected request task
        let objIndex = section.cards.findIndex((obj => obj.id == e.id));        // Find index of specific object using findIndex method.    
        
        section.cards.splice(objIndex, 1);
        updateContent({columns});
    }

    const editColumn = async(title, id) => {
        let columns = content.columns;

        const { value: column_name } = await Swal.fire({
            input: 'text',
            inputLabel: 'Edit Column Name',
            inputValue: title,
            inputPlaceholder: 'Enter the Column Name',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showCloseButton: true,
            reverseButtons: true,
            inputValidator: (value) => {
                if (!value) {
                  return 'You need to write something!'
                }
            }
        });

        if (column_name) {
            const params = {
                id: id,
                title: column_name,
            }

            Api.updateColumnName(params).then(res => {
            const result = res.data;
            if(result.status == 'validation failed') return openSwal({icon: 'error', title: 'Ooops...', text: result.message.title[0]})
                console.log(result);
                const column_index = columns.findIndex(column => column.id == result.id);
                columns[column_index].title = result.title;
                updateContent({columns});
                return openSwal({icon: 'success', title: 'Successfully Updated Column', text: ''})
            }).catch(error =>{
                return openSwal({icon: 'error', title: 'Oops...', text: `There's an error when updating column`})
            });
        }
    }

    const deleteColumn = async(title, id) => {
        Swal.fire({
            title: `<strong>Delete ${title}?</strong>`,
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-trash"></i> Yes, delete it!',
            confirmButtonAriaLabel: 'Yes, Delete!',
            reverseButtons: true,
        }).then((result) => {
            if(result.isConfirmed) {
                Api.deleteStatusColumn(id).then(res => {
                    const result = res.data;
                    if(result.status == 'validation failed') return openSwal({icon: 'error', title: 'Ooops...', text: result.message.title[0]})
                    console.log(result);
                    props.updateBoard();
                    return openSwal({icon: 'success', title: `Successfully deleted ${title}`, text: ''})
                }).catch(error =>{
                    return openSwal({icon: 'error', title: 'Oops...', text: `There's an error when deleting column`})
                });
            }
        });
    }

    const openSwal = (data) => {
        Swal.fire({
            icon: data.icon,
            title: data.title,
            text: data.text
        })
    }

    let boardColumns = content.columns;
    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col>
                    <Board
                        className="swimlane"
                        children={content}
                        renderColumnHeader={({ title, id, current_page, last_page, hash }) => (
                            <RenderCardTitle userRole={props.userRole} title={title} id={id} currentPage={current_page} lastPage={last_page} hash={hash} editColumn={editColumn} deleteColumn={deleteColumn} retrieveNextItems={props.retrieveNextItems}/>
                        )}
                        renderCard={({ content }, { dragging }) => (
                            <CardTaskBox requestDetails={props.requestDetails} data={content} columns={boardColumns} cardID={stateCard.cardID} preloadCard={stateCard.preloadCard} dragging={dragging} coders={props.coders} updateSection={updateSection.bind(this)}>
                                {content}
                            </CardTaskBox>
                        )}
                        onNewCardConfirm={draftCard => ({
                            id: new Date().getTime(),
                            ...draftCard
                        })}
                        disableCardDrag={stateCard.preloadCard}
                        onCardDragEnd={(card, source, destination) => updateBoard(card, source, destination)}
                        onColumnDragEnd={(column, source, destination) => updateBoardColumn(column, source, destination)}
                        // disableColumnDrag={(user_details.role === 'super admin' || user_details.role === 'admin') ? false : true}
                        disableColumnDrag={true}
                    />

                    {isLoading &&
                        <div id="preloader" style={{display: 'block', backgroundColor: 'rgba(255, 255, 255, 0.4)'}}>
                            <div id="status">
                                <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                </div>
                            </div>
                        </div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
}
        
export default ControlledBoard;