

import React, { useEffect, useState } from 'react';
import Api from "../../Utils/API";
import HtmlParser from 'react-html-parser';
import Select from "react-select";
import File from "../../assets/images/file.png";
import { customStyles, download, emailValidator } from '../../Utils/helper';

let userOptions = [];
export const SendEmail = (props) => {
    const [selectedEmail, setselectedEmail] = React.useState({
        to: [],
        cc: [],
    });

    const [state, setState] = useState({
        request_details: props.props,
        userOptions: '',
        report_note: '',
        url: '',
        preload_btn: false,
        preload_new_user: false,
        emailError: '',
    });

    useEffect(() => {
        console.log(state.request_details);
        getUsers();
    }, []);


    const getUsers = () => {
        Api.allUser().then(result => {
            let users = result.data;
            for (let index = 0; index < users.length; index++) {
                userOptions.push({
                    value: users[index].id,
                    label: `${users[index].firstname} <${users[index].email}> `,
                    email: users[index].email
                });

                // // Auto-fill "To: " in public reply
                // if(props.selectedEmail && props.selectedEmail.sender){
                //     if(users[index].email == props.selectedEmail.sender.email){
                //         let default_email_to = {
                //             value: users[index].id,
                //             label: `${users[index].firstname} <${users[index].email}> `,
                //         }
                        
                //         setselectedEmail({ ...selectedEmail, to: [default_email_to]})
                //     }
                // }
            }
            
            console.log('user options: ', userOptions)
            setState({
                ...state,
                userOptions: userOptions,
                // email_id: props.selectedEmail ? props.selectedEmail.id :''
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const submit = () => {
        setState(prevState => ({...prevState, preload_btn: true}));
        const { report_note, request_details, url } = state;
        let { confirmButton, email_details } = props;
        let emails = selectedEmail.to.concat(selectedEmail.cc);
        let params = {
            report_note: report_note,
            request_id: request_details.id,
            url: url,
            emails: emails
        }

        email_details(params);
        setState(prevState => ({...prevState, preload_btn: false}));
        confirmButton.clickConfirm();
        // Api.storeReport(params).then(result => {
        //     report_details(params);
        //     setState(prevState => ({...prevState, preload_btn: false}));
        //     confirmButton.clickConfirm();
        // }).catch(error =>{
        //     console.log(error);
        //     setState(prevState => ({...prevState, preload_btn: false}));
        //     // REVERT STATUS CHANGE
        //     revertBoardChanges();
        // });
    }

    const handleInputChange = (value) => {
        setState(prevState => ({...prevState, report_note: value}));
    }

    const handleUrlChange = (value) => {
        setState(prevState => ({...prevState, url: value}));
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const onChangecc = (e) => {
        setselectedEmail({ ...selectedEmail, cc: e ? e : [] })
    }

    const onChangeto = (e) => {
        setselectedEmail({ ...selectedEmail, to: e ? e : [] })
    }

    const createNewUser = (user) => {
        setState({...state, preload_new_user: true})
        const emailError = emailValidator(user);
        const firstname = user.split('@')[0];             // get the name before the '@' on email, and store it as firstname
        const params = {
            email: user,
            company_id: 1,
            firstname: firstname,
            role: 'user',
        }

        if(emailError) 
            return setState(prevState => ({...prevState, emailError: emailError, preload_new_user: false}));
        
        Api.saveUser(params).then(result => { 
            const user = result.data;
            const new_user = {
                value: user.id,
                label: `${user.firstname} <${user.email}> `,
                email: user.email
            };

            userOptions.push(new_user);                   // add to user options
            setState(prevState => ({...prevState, userOptions: userOptions, preload_new_user: false}))
        }).catch(error => {
            console.log(error)
            setState(prevState => ({...prevState, preload_new_user: false}))
        });
    }

    const noOptionsMessage = (e) => {
        const user = e.inputValue;
        return (
            <React.Fragment>
                <div className="create-company" onClick={() => createNewUser(user)}>
                    { state.preload_new_user ? 
                        <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-1"></i>
                            Creating new user
                        </>
                        : 
                        <>
                            <p>Create <span style={{fontWeight: '500'}}>"{user}"</span></p>
                            {state.emailError && <label className="eror-fields">{state.emailError}</label>}
                        </>
                    }
                </div>
            </React.Fragment>
        )
    }

    const { request_details, preload_btn, report_note, url } = state;

    const reportDetails = () => {
        return(
            <React.Fragment>
                {request_details.content.report && 
                    <div>
                        <div>
                            <h2 className="subtitle report">Report</h2>
                            <p>{request_details.content.report.report_note || ''}</p>
                        </div>

                        <div>
                            <h2 className="subtitle">Report Files</h2>
                            <div className="report-files-container">
                                {request_details.content.report.files.map((file, index) => {
                                    return (
                                        <div className="report-file" key={index}>
                                            <img src={File} />
                                            <div className="file_details">
                                                <h2 className="text-truncate" 
                                                    data-toggle="tooltip" 
                                                    data-placement="top" 
                                                    title={file.name}
                                                >
                                                    {file.name}
                                                </h2>
                                                <h2>{formatBytes(file.size)}</h2>
                                            </div>
                                            <i className="far fa-arrow-alt-circle-down file-download" onClick={() => download(file.url, file.name)}></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

    return ( 
        <React.Fragment>
            <div className="row">
                <div className="col-8" id="sendEmail">
                    <h2 className="header">Complete the Task</h2>
                    {/* RECIPIENTS */}
                    <div>
                        <div className='flex-row item-baseline'>
                            <div className="recipient">To:</div>
                            <div className="flex-1">
                                <Select
                                    value={selectedEmail.to}
                                    isMulti
                                    className="form-select"
                                    styles={customStyles}
                                    onChange={onChangeto}
                                    onInputChange={(e) => setState({...state, emailError: ''})}
                                    options={state.userOptions}
                                    placeholder="Select user"
                                    noOptionsMessage={(e) => noOptionsMessage(e)}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex-row item-baseline'>
                            <div className="recipient">CC:</div>
                            <div className="flex-1">
                                <Select
                                    isMulti
                                    className="form-select"
                                    styles={customStyles}
                                    onChange={onChangecc}
                                    options={state.userOptions}
                                    placeholder="Select user"
                                    noOptionsMessage={(e) => noOptionsMessage(e)}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* URL FOR APPROVAL */}
                    <div>
                        <h2 className="subtitle">URL for approval (if the work can be shown on a web page)</h2>
                        <input className="url-for-approval" onChange={(e) => handleUrlChange(e.target.value)}/>
                    </div>

                    {/* COMPOSE A REPORT */}
                    <div className="report-note-container">
                        <h2 className="subtitle report-note">Compose a Report</h2>
                        <ul>
                            <li>Describe what you did</li>
                            <li>Any Issues Faced and Resolution</li>
                            <li>Any Recommendations</li>
                        </ul>
                        <textarea id="report-note" name="report-note" rows="6" placeholder="Write something..." onChange={(e) => handleInputChange(e.target.value)}></textarea>
                    </div>
                    <button className="submit-btn" onClick={submit} disabled={preload_btn || (!report_note && !url)}> 
                       {preload_btn ? <i className="bx bx-loader bx-spin font-size-14 align-middle"></i> : 'Send Email'} 
                    </button>
                </div>

                {/* TASK DETAILS */}
                <div className="col-4" id="taskDetails">
                    <h2 className="header">Task Details</h2>
                    <div>
                        <h2 className="subtitle task-title">Task Title</h2>
                        <p>{request_details.content.title}</p>
                    </div>
                    <div>
                        <h2 className="subtitle description">Description</h2>
                        <p>{HtmlParser(request_details.content.description) || ''}</p>
                    </div>
                    <div>
                        <h2 className="subtitle clarified-description">Clarified Description</h2>
                        <p>{HtmlParser(request_details.content.clarified_description_to_client) || ''}</p>
                    </div>

                    {/* REPORT DETAILS */}
                    {reportDetails()}
                </div>
            </div>
        </React.Fragment>
    )
}

export default SendEmail;
