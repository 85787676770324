
import React, { useState, useEffect } from 'react';
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import ContactFormBody from "./ContactFormBody";

export const AddContact = (props) => {
    const [state, setState] = React.useState({ value: null });
    const [isMinimized, setIsMinimized] = React.useState(false);

    const handleChange = value => {
        setState({ value });
        props.handleChange(value);
    };

    useEffect(() => {
        if (props.clear) {
            setState('');
        }
    }, [props]);

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setIsMinimized(true);
    }

    function openForm(event) {
        event.stopPropagation();
        setIsMinimized(false);
        document.body.classList.remove('minimize-form');
    }
   

    return (
        <React.Fragment>
            <div className="contact-form" >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1"> Add Contact</div>
                    <div className="row-2">
                        {isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    <ContactFormBody company={props.company} newContact={props.newContact} reloadData={props.reloadData} userTypeOptions={props.userTypeOptions} />
                </div>

            </div>
        </React.Fragment>
    );
};

export default AddContact;
