import React, {useState,useEffect} from 'react';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

const TaskProgressSlider = (props) => {
    const [disabled, setDisabled] = useState(false), [percent, setPercent] = useState(0);

    useEffect(() => {setPercent(props.start); setDisabled(props.disabled);}, [props])

    const onSlide = (render,handle,value,un,percent) => {setPercent(percent[0].toFixed(0))};

    // update task percent via api
    const onSet = (render,handle,value,un,percent) => {props.update(percent[0].toFixed(0))};

    return (
        <>
            <h6 className="title-1">Progress: {percent}%</h6>
            <Nouislider style={{zIndex: 0, elevation: 0}} behaviour="tap" range={{min:props.min,max:props.max}} tooltips={props.tooltips} start={props.start} animate={true} connect={[true, false]} disabled={disabled} onSlide={onSlide} onSet={onSet} step={1}/>
        </>
    );
}

export default TaskProgressSlider;