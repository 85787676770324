

import React, { useEffect, useState } from 'react';
import { customStyles, floatValidator } from '../../Utils/helper';
import Select from "react-select";
import DatePicker from "react-datepicker";
import calendarIcon from "../../assets/images/calendar.png";
import closeIcon from "../../assets/images/closeIcon.png";

export const SubmitManualPayment = (props) => {
    const [state, setState] = useState({
        amount: 0.00,
        credits: 0.00,
        contact: null,
        contacts: props.contacts,
        rn: '',
        date: '',
        amountError: '',
        creditsError: '',
        contactError: '',
        rnError: '',
        dateError: '',
        loading: false
    });

    useEffect(() => {
        props.api.getCompanyContacts(props.company).then(result => {  
            console.log('CONTACTS', result.data)
            setState(prevState => ({ ...prevState, contacts: result.data }));
        }).catch(error => {
            console.log(error);
            setState(prevState => ({ ...prevState, loading: false }));
        });
    }, [])

    const updateDate = (e) => {
        setState({ ...state, date: e, dateError: '' });
    }

    const submit = () => {
        setState(prevState => ({ ...prevState, loading: true }));
        let {amount, credits, contact, rn, date} = state;
        let amountError = floatValidator(amount);
        let creditsError = floatValidator(credits);
        let contactError = contact ? '' : 'Please select a contact!';
        let dateError = date ? '' : 'Please select a date!';

        if (amountError || creditsError || contactError || dateError ) {
            return setState(prevState => ({ ...prevState, amountError, creditsError, contactError, dateError, loading: false }));
        }

        date = props.moment(date).format("YYYY-MM-DD");

        const params = {
            company: props.company,
            contact: contact.value,
            amount: amount,
            credits: credits,
            refenceNo: rn,
            date: date
        };

        props.api.addManualPayment(params).then(result => {
            const data = result.data
            if(data.status && data.status === 'validation failed') {
                const messages = data.message;

                if(messages['amount']) {
                    amountError = messages['amount'][0];
                }

                if(messages['credits']) {
                    creditsError = messages['credits'][0];
                }

                if(messages['contact']) {
                    contactError = messages['contact'][0];
                }

                if (amountError || creditsError || contactError || dateError ) {
                    return setState(prevState => ({ ...prevState, amountError, creditsError, contactError, dateError, loading: false }));
                }
            }
            setState(prevState => ({
                ...prevState,
                amount: 0.00,
                credits: 0.00,
                contact: null,
                contacts: props.contacts,
                rn: '',
                date: '',
                amountError: '',
                creditsError: '',
                contactError: '',
                rnError: '',
                dateError: '',
                loading: false
            }));

            // props.updatePaymentsSection(data);
            props.manualPaymentAdded();

            props.swalbtn.clickConfirm();
        }).catch(error => {
            console.log(error);
            setState(prevState => ({ ...prevState, loading: false }));
        });
    }

    const cancel = () => {
        const {swalbtn} = props;
        swalbtn.clickCancel();
    }

    const updateNumericInput = (e, attrib) => {
        let value = e.target.value;
        let error = '';

        if(Number.isNaN(parseFloat(value))) {
            error = `${attrib} should be a number!`;
        }

        if(attrib === 'Credits') {
            setState(prevState => ({ ...prevState, credits: value, creditsError: error }));
        } else if(attrib === 'Amount') {
            setState(prevState => ({ ...prevState, amount: value, amountError: error }));
        } else {
            console.log('Attrib is neither "Credits" nor "Amount"');
        }
    }

    return ( 
        <React.Fragment>
            <div className="col-12" id="submitPayment">
                <div className="row">
                    <h2 className="header">Manual Payment</h2>
                    <img className={`close-manual-payment btn ${state.loading ? 'disabled' : ''}`} src={closeIcon} onClick={state.loading ? null : cancel} disabled={state.loading}/>
                </div>
                <div className="upload-payment-container">
                    <div className="contact-group">
                        <div className="label">
                            Amount
                        </div>
                        <div className="row-2 amount" style={{ alignItems: 'center' }}>
                            <span>$</span><input type="number" className="form-control" placeholder="0.00" value={state.amount} onChange={(e) => { updateNumericInput(e, 'Amount') }}/>
                        </div>
                        {
                            state.amountError ?
                                <label className="error-fields">{state.amountError}</label>
                                : ''
                        }
                        <div className="separator"></div>
                    </div>

                    <div className="contact-group">
                        <div className="label">
                            Credits
                        </div>
                        <div className="row-2 amount" style={{ alignItems: 'center' }}>
                            <input type="number" className="form-control" placeholder="0.00" value={state.credits} onChange={(e) => { updateNumericInput(e, 'Credits') }} />
                        </div>
                        {
                            state.creditsError ?
                                <label className="error-fields">{state.creditsError}</label>
                                : ''
                        }
                    </div>

                    <div className="contact-group">
                        <div className="label">
                            Contact
                        </div>
                        <div className="row-2">
                            <Select
                                className="form-select"
                                styles={customStyles}
                                options={state.contacts}
                                placeholder="Select Contact"
                                noOptionsMessage={() => null}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                value={state.contact}
                                onChange={(e) => { setState({ ...state, contact: e, contactError: '' }) }}
                            />
                        </div>
                        {
                            state.contactError ?
                                <label className="error-fields">{state.contactError}</label>
                                : ''
                        }
                    </div>

                    <div className="contact-group">
                        <span className="label">
                            Reference No.
                        </span>
                        <div className="row-2">
                            <input className="form-control" placeholder="Enter Reference No." value={state.rn} onChange={(e) => { setState({ ...state, rn: e.target.value, rnError: '' }) }} />
                        </div>
                        {
                            state.rnError ?
                                <label className="error-fields">{state.rnError}</label>
                                : ''
                        }
                    </div>

                    <div className="contact-group" style={{ marginBottom: '28px' }}>
                        <div className="label">
                            Date
                        </div>
                        <div className="row-2" style={{ alignItems: 'center' }}>
                            <img className="icon" src={calendarIcon}/>
                            <DatePicker
                                className="datepicker"
                                placeholderText={"Select Date"}
                                selected={state.date}
                                maxDate={new Date()}
                                onChange={updateDate}
                                dateFormat={`dd MMM yyyy, hh:mm aa`}
                            />
                        </div>
                        {
                            state.dateError ?
                                <label className="error-fields">{state.dateError}</label>
                                : ''
                        }
                    </div>

                    <div className="contact-group buttons">
                        <button disabled={state.loading} className="btn cancel-payment" onClick={state.loading ? null : cancel}>
                            {
                                state.loading ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle"></i>)
                                    : 'Cancel'
                            }
                        </button>
                        <button disabled={state.loading} className="btn btn-primary add-payment" onClick={state.loading ? null : submit}>
                            {
                                state.loading ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle"></i>)
                                    : 'Add'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SubmitManualPayment;
