import React, { useEffect } from 'react';
import Api from "../Utils/API";
import closeIcon from "../assets/images/closeIconWhite.png";
import minimizeIcon from "../assets/images/minimizeIcon.png";
import maximizeIcon from "../assets/images/maximizeIcon.svg";
import Select from "react-select";
import { customStyles } from '../Utils/helper';
import { ipAddressValidator, numberValidator, urlValidator } from '../Utils/helper';
import { handleErrors } from '../Utils/handleError';
import attachmentIcon from "../assets/images/attachmentIcon.png";
import Swal from 'sweetalert2';
import TeamAssignmentRow from './PropertyManagement/TeamAssignmentRow';

const defaultCredentials = [
    {
        name: 'SSH Host',
        details: {
            username: '',
            pem: '',
            port: ''
        }
    },
    {
        name: 'Database',
        details: {
            host: '',
            port: '',
            username: '',
            password: '',
            dbname: '',
            withSsl: 0
        }
    },
];

function Host(props, i) {
    const [state, setState] = React.useState({
        moreDetails: [],
        server_type: [],
        hostOptions: props.host,
        credentials: props.credentials,
        name: 'SSH Host',
        serverTypeOptions: props.serverTypeOptions,
        pemFile: 0,
        isSubmit: false,
        pems: [],
        isMinimized: false,
        ip_address_error: false
    });

    return (
        <React.Fragment>
            <h4 className="website-font mt-4">SSH Host</h4>
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Username
                        </div>
                        <div className="row-2">
                            <input counter={props.i} name="username" className="form-control" placeholder="Enter Username" value={props.items.details.username || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            Pem Key
                        </div>
                        <div className="row-2">
                            <input counter={props.i} className="form-control" placeholder="Attach file" onClick={() => { document.getElementById(`add-server-pem-${props.i}`).click(); }} />
                        </div>
                        <div>
                            <img src={attachmentIcon} onClick={() => { document.getElementById('add-server-pem').click(); }} style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            IP Address
                        </div>
                        <div className="row-2">
                            <input counter={props.i} name="ip" className="form-control" placeholder="Enter IP Address" value={props.credentials.details.ip_address || ''} onChange={(e) => props.updateCredentials(props.credIndex, 'details', 'ip_address', e.target.value)} />
                            {
                                props.credentials.details.ip_address_error ?
                                    <label className="eror-fields">{props.credentials.details.ip_address_error}</label>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            Port
                        </div>
                        <div className="row-2">
                            <input name="port" counter={props.i} type="number" min="0" onKeyDown={props.editPort} className="form-control" placeholder="Enter Port" onChange={props.handleChange} value={props.items.details.port || ''} />
                            {
                                props.items.details.portError ?
                                    <label className="eror-fields">{props.items.details.portError}</label>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function Database(props, i) {
    return (
        <React.Fragment>
            <h4 className="website-font mt-4">Database</h4>
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Host
                        </div>
                        <div className="row-2">
                            <input counter={props.i} name="host" className="form-control" placeholder="Enter URL" value={props.items.details.host || ''} onChange={props.handleChange} />
                            {
                                props.items.details.hostError ?
                                    <label className="eror-fields">{props.items.details.hostError}</label>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            Port
                        </div>
                        <div className="row-2">
                            <input name="port" counter={props.i} type="number" min="0" onKeyDown={props.editPort} className="form-control" placeholder="Enter Port" onChange={props.handleChange} value={props.items.details.port || ''} />
                            {
                                props.items.details.portError ?
                                    <label className="eror-fields">{props.items.details.portError}</label>
                                    : ''
                            }
                        </div>

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Password
                        </div>
                        <div className="row-2">
                            <input counter={props.i} name="password" className="form-control" placeholder="Enter Password" value={props.items.details.password || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            DB Name
                        </div>
                        <div className="row-2">
                            <input counter={props.i} name="dbname" className="form-control" placeholder="Enter Name" value={props.items.details.dbname || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function Other(props, i) {
    return (
        <React.Fragment>
            <input type="5" counter={props.i} name="name" className="form-control other-text" placeholder="Enter Name" value={props.items.name || ''} onChange={props.handleChange} />
            <div className="row ml-2">
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Username
                        </div>
                        <div className="row-2">
                            <input type="4" counter={props.i} name="username" className="form-control" placeholder="Enter Username" value={props.items.details.username || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            URL
                        </div>
                        <div className="row-2">
                            <input type="4" counter={props.i} name="url" className="form-control" placeholder="Enter URL" value={props.items.details.url || ''} onChange={props.handleChange} />
                            {
                                props.items.details.urlError ?
                                    <label className="eror-fields">{props.items.details.urlError}</label>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="contact-group">
                        <div className="row-1">
                            Password
                        </div>
                        <div className="row-2">
                            <input type="4" counter={props.i} name="password" className="form-control" placeholder="Enter Password" value={props.items.details.password || ''} onChange={props.handleChange} />
                        </div>
                    </div>
                    <div className="contact-group">
                        <div className="row-1">
                            Port
                        </div>
                        <div className="row-2">
                            <input name="port" counter={props.i} type="number" min="0" onKeyDown={props.editPort} className="form-control" placeholder="Enter Port" onChange={props.handleChange} value={props.items.details.port || ''} />
                            {
                                props.items.details.portError ?
                                    <label className="eror-fields">{props.items.details.portError}</label>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function CreateUI(props) {
    return props.items.map((el, i) =>
        <div key={i} className="mb-4">
            {
                el.type === '1' ?
                    <Host
                        handleChange={props.handleChange}
                        UploadFile={props.UploadFile}
                        items={el}
                        i={i}
                        updateCredentials={props.updateCredentials.bind(this)}
                        credIndex={props.credIndex}
                        credentials={props.credentials}
                        editPort={props.editPort}
                    />
                    :
                    (el.type === '2' ?
                        <Database
                            handleChange={props.handleChange}
                            items={el}
                            i={i}
                            updateCredentials={props.updateCredentials.bind(this)}
                            credIndex={props.credIndex}
                            credentials={props.credentials}
                            editPort={props.editPort}
                        />

                        :
                        (el.type === '3' ?
                            <Other
                                handleChange={props.handleChange}
                                items={el}
                                i={i}
                                updateCredentials={props.updateCredentials.bind(this)}
                                credIndex={props.credIndex}
                                credentials={props.credentials}
                                editPort={props.editPort}
                            /> : ''
                        )

                    )
            }
        </div>
    )
}

export const UpdateServer = React.memo(props => {
    const [state, setState] = React.useState({
        company_id: props.companyId,
        ismoreCredential: false,
        moreDetails: [],
        primary_contact: [],
        server_type: [],
        environment: [],
        hosted_by: [],
        hostOptions: props.host,
        credentials: props.propertyDetails.credentials,
        name: '',
        id: 0,
        serverTypeOptions: props.serverTypeOptions,
        contacts: props.contacts,
        invironmentOptions: props.invironments,
        pemFile: 0,
        isSubmit: false,
        pems: [],
        companies: props.companies || [],
        companyOptions: [],
        selectedCompany: '',
        isMinimized: false,
        pricingMethods: [],
        projectManagers: [],
        developers: [],
        qas: [],
        designers: [],
        pricing: null,
    });

    useEffect(() => {
        const property = props.propertyDetails;
        const companies = [];
        let selectedCompany = ''
        const propery_details = typeof property.details == 'string' ? JSON.parse(property.details) : property.details;
        const assignees = props.propertyDetails.assignees;
        const projectManagers = [];
        const designers = [];
        const developers = [];
        const qas = [];

         // if credential details is fetched as string
         props.propertyDetails.credentials.forEach(credential => {
            if(typeof credential.details == 'string'){
                let credential_details = JSON.parse(credential.details);
                credential.details = credential_details;
            }
        });

        if(state.companies.length > 0){
            state.companies.map(company => {
                let company_details = {value: company.id, label: company.name}
                companies.push(company_details)
            });

            selectedCompany = {
                value: property.company.id,
                label: property.company.name
            }
        }

        const pricing = {
            value: property.pricing?.id,
            label: property.pricing?.name
        }

        if(assignees.length) {
            assignees.map((assignee) => {
                switch (assignee.type) {
                    case "project manager":
                        return projectManagers.push(assignee.user.id);
                    case "designer":
                        return designers.push(assignee.user.id);
                    case "qa":
                        return qas.push(assignee.user.id);
                    default:
                        return developers.push(assignee.user.id);
                }
            });

            setState({
                ...state,
                id: property.id,
                name: property.name,
                environment: propery_details.environment,
                hosted_by: propery_details.environment,
                // primary_contact: primary_contact,
                credentials: props.propertyDetails.credentials,
                companyOptions: companies,
                selectedCompany: selectedCompany,
                projectManagers,
                designers,
                developers,
                qas,
                pricing
            });
        } else {
            setState({
                ...state,
                id: property.id,
                name: property.name,
                environment: propery_details.environment,
                hosted_by: propery_details.environment,
                // primary_contact: primary_contact,
                credentials: props.propertyDetails.credentials,
                companyOptions: companies,
                selectedCompany: selectedCompany,
                projectManagers,
                designers,
                developers,
                qas,
                pricing
            });
        }

        retrieveContacts(props.propertyDetails.company_id);
        getPricingMethodOptions();
    }, [props, JSON.stringify(props.propertyDetails.assignees)]);

    function getPricingMethodOptions() {
        Api.getPricingMethodOptions().then(result => {
            setState((prevState) => ({
                ...prevState,
                pricingMethods: result.data
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    function updateCredentials(index, data, subdata, _value) {
        let temp = state.credentials;
        if (subdata != null) {
            temp[index][data][subdata] = _value;

            if(subdata === 'ip_address') {
                const ipAddressError = ipAddressValidator(_value);

                if(ipAddressError) {
                    temp[index][data]['ip_address_error'] = ipAddressError;
                } else {
                    temp[index][data]['ip_address_error'] = false;
                }
            }
        } else {
            temp[index][data] = _value;
        }

        setState({ ...state, credentials: temp })
    }

    function submitServer(event) {
        event.preventDefault();
        setState({ ...state, isSubmit: true });
        const { credentials } = state;

        // validate server name using urlValidator(string: url, boolean(optional, defaults to false): isRequired)
        const nameError = urlValidator(state.name, true)

        let hasErrors = false;
        for (let i = 0; i < credentials.length; i++) {
            // check for ip address error
            if(credentials[i].details.ip_address_error) {
                hasErrors = true;
            }

            // check port fields for input errors
            if(credentials[i].details.port) {
                credentials[i].details.portError = numberValidator(credentials[i].details.port);
                if(credentials[i].details.portError) {
                    hasErrors = true;
                }
            }

            // validate url using urlValidator(string: url, boolean(optional, defaults to false): isRequired)
            if(credentials[i].details.port) {
                credentials[i].details.urlError = urlValidator(credentials[i].details.url);
                if(credentials[i].details.urlError) {
                    hasErrors = true;
                }
            }

            // validate host using urlValidator(string: url, boolean(optional, defaults to false): isRequired)
            if(credentials[i].details.host) {
                credentials[i].details.hostError = urlValidator(credentials[i].details.host);
                if(credentials[i].details.hostError) {
                    hasErrors = true;
                }
            }
        }

        if (hasErrors) {
            setState({ ...state, isSubmit: false, nameError, credentials });
            return Swal.fire('Error', 'Please enter valid input!', 'error')
        }

        if(state.companies.length > 0 && !state.selectedCompany){
            setState({ ...state, isSubmit: false });
            return Swal.fire('Ooops!', 'Please select a company!', 'error')
        }

        const details = {
            type: state.type,
            environment: state.environment,
            hosted_by: state.hosted_by
        };

        let files = state.pems;
        const data = new FormData();

        data.append('id', state.id);
        data.append('name', state.name);
        data.append('company_id', state.companies.length > 0 ? state.selectedCompany.value : state.company_id);
        // data.append('primary_contact_id', state.primary_contact.value);
        data.append('type', 'server');
        data.append('details', JSON.stringify(details));
        data.append('credentials', JSON.stringify(credentials));
        data.append('pricing_id', state.pricing?.value);
        data.append('project_managers', JSON.stringify(state.projectManagers));
        data.append('developers', JSON.stringify(state.developers));
        data.append('qas', JSON.stringify(state.qas));
        data.append('designers', JSON.stringify(state.designers));

        // if (files.length > 0) {
        //     for (let index = 0; index < files.length; index++) {
        //         data.append('pems[]', files[index]);
                
        //     }

        //     for (let index = 0; index < pemCredIndexArray.length; index++) {
        //         data.append('pemCredIndexArray[]', pemCredIndexArray[index]);
        //     }
        // }

        Api.updateCompanyPropertiesWithFiles(data).then(result => {
            props.updateProperty(data);
            setState({
                ...state,
                credentials: defaultCredentials,
                ismoreCredential: false,
                description: '',
                moreDetails: [],
                url: '',
                primary_contact: [],
                username: '',
                password: '',
                isSubmit: false
            });
        }).catch(error => {
            handleErrors();
            setState({ ...state, isSubmit: false });
        });
    }

    const moreDetails = (e) => {
        let type = e.target.getAttribute("type");
        let values = [...state.credentials];
        if (type === '1') {
            values.push({
                type: type,
                name: 'Host',
                details: {
                    username: '',
                    password: '',
                    url: ''
                }
            });
        }
        if (type === '2') {
            values.push({
                type: type,
                name: 'Database',
                details: {
                    host: '',
                    port: '',
                    username: '',
                    password: '',
                    dbname: '',
                    withSsl: 0
                }
            });
        }
        if (type === '3') {
            values.push({
                type: type,
                name: '',
                details: {
                    username: '',
                    password: '',
                    url: '',
                    port: ''
                }
            });
        }
        setState({ ...state, credentials: values, ismoreCredential: false });
    }

    function handleChange(event) {
        let type = event.target.getAttribute('type');
        let values = [...state.credentials];
        if (type === '5') {
            values[event.target.getAttribute('counter')][event.target.getAttribute('name')] = event.target.value;
        } else {
            values[event.target.getAttribute('counter')].details[event.target.getAttribute('name')] = event.target.value;
        }

        setState({ ...state, credentials: values });
    }

    function handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let temp = state.credentials;
        let pems = state.pems;
        let index = state.pemFile;
        temp[state.pemFile].details.pem = index;
        pems[index] = file;
        index++;
        setState({ ...state, credentials: temp, pems: pems, pemFile: index });
    }

    function UploadFile(event) {
        let index = event.target.getAttribute('counter');
        setState({ ...state, pemFile: index });
        document.getElementById('add-server-pem').click();
    }

    function minimizeForm(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');

        setState({
            ...state, isMinimized: true
        })
    }

    function openForm(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');

        setState({
            ...state, isMinimized: false
        })
    }

    function editPort(event) {
        if(event.key == 'e' || event.key == '-' || event.key == '+') {
            event.preventDefault();
            return false;
        }
    }

    const retrieveContacts = (compId) => {
        Api.getCompanyContacts(compId).then(result => {
            const contactOptions = result.data;
            const selectedContact = contactOptions.filter(cont => cont.value === JSON.parse(props.propertyDetails.details).contact_id);
            setState((prevState) => ({
                ...prevState,
                contactOptions,
                selectedContact
            }));
        }).catch(error => {
            console.log(error);
            handleErrors();
        });
    }

    const updateDesigners = (id, prev = null) => {
        const {designers} = state;
        let prevIndex = prev ? designers.indexOf(prev) : -1;

        if(designers.includes(id)) {
            // remove id from the array
            designers.splice(designers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the designers array
            if(prev && designers.includes(prev)) {
                // retrieve prev's index
                prevIndex = designers.indexOf(prev);
                // remove prev from the array
                designers.splice(prevIndex, 1, id);
            } else {
                designers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const updateDevelopers = (id, prev = null) => {
        const {developers} = state;
        let prevIndex = prev ? developers.indexOf(prev) : -1;

        if(developers.includes(id)) {
            // remove id from the array
            developers.splice(developers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the developers array
            if(prev && developers.includes(prev)) {
                // retrieve prev's index
                prevIndex = developers.indexOf(prev);
                // remove prev from the array
                developers.splice(prevIndex, 1, id);
            } else {
                developers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const updateProjectManagers = (id, prev = null) => {
        const {projectManagers} = state;
        let prevIndex = prev ? projectManagers.indexOf(prev) : -1;

        if(projectManagers.includes(id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the projectManagers array
            if(prev && projectManagers.includes(prev)) {
                // retrieve prev's index
                prevIndex = projectManagers.indexOf(prev);
                // remove prev from the array
                projectManagers.splice(prevIndex, 1, id);
            } else {
                projectManagers.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const updateQas = (id, prev = null) => {
        const {qas} = state;
        let prevIndex = prev ? qas.indexOf(prev) : -1;

        if(qas.includes(id)) {
            // remove id from the array
            qas.splice(qas.indexOf(id), 1);
        } else {
            // check if prev is not null and if it is in the qas array
            if(prev && qas.includes(prev)) {
                // retrieve prev's index
                prevIndex = qas.indexOf(prev);
                // remove prev from the array
                qas.splice(prevIndex, 1, id);
            } else {
                qas.push(id);
            }
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const removeDesigner = (assignee) => {
        const {designers} = state;
        if(designers.includes(assignee.id)) {
            // remove id from the array
            designers.splice(designers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            designers
        }));
    }

    const removeDeveloper = (assignee) => {
        const {developers} = state;
        if(developers.includes(assignee.id)) {
            // remove id from the array
            developers.splice(developers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            developers
        }));
    }

    const removeProjectManager = (assignee) => {
        const {projectManagers} = state;
        if(projectManagers.includes(assignee.id)) {
            // remove id from the array
            projectManagers.splice(projectManagers.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            projectManagers
        }));
    }

    const removeQa = (assignee) => {
        const {qas} = state;
        if(qas.includes(assignee.id)) {
            // remove id from the array
            qas.splice(qas.indexOf(assignee.id), 1);
        }

        setState((prevState) => ({
            ...prevState,
            qas
        }));
    }

    const selectPricing = (e) => {
        setState({ ...state, pricing: e });
    }

    return (
        <React.Fragment>
            <input accept=".pem" style={{ display: 'none' }} id="add-server-pem" type="file" onChange={(e) => handleImageChange(e)} />

            <div className="contact-form website-form"  >
                <div className="form-header" onClick={(e) => openForm(e)} >
                    <div className="row-1">Update Server</div>
                    <div className="row-2">
                        {state.isMinimized ?
                            <button className="btn btn-icon" style={{boxShadow: '0 !important'}} onClick={(e) => openForm(e)} > <img src={maximizeIcon} /> </button>
                            :
                            <button className="btn btn-icon" onClick={(e) => minimizeForm(e)} > <img src={minimizeIcon} /> </button>
                        }
                        <button className="btn btn-icon" onClick={props.close} > <img src={closeIcon} /> </button>
                    </div>
                </div>
                <div className="form-body">
                    <div style={{borderBottom: "1px solid #ECECF5", padding: "0px", paddingBottom: "22px"}}>
                        <h4 className="website-font mt-4">Team</h4>
                        <div className="row ml-2">
                            <div className="col-lg-6" style={{paddingLeft: 0}}>
                                <div className="contact-group" style={{borderBottom: 'none'}}>
                                    <TeamAssignmentRow
                                        projectManagers={state.projectManagers}
                                        developers={state.developers}
                                        qas={state.qas}
                                        designers={state.designers}
                                        pricing={state.pricing}
                                        updateProjectManagers={updateProjectManagers}
                                        updateDevelopers={updateDevelopers}
                                        updateQas={updateQas}
                                        updateDesigners={updateDesigners}
                                        removeProjectManager={removeProjectManager}
                                        removeDeveloper={removeDeveloper}
                                        removeQa={removeQa}
                                        removeDesigner={removeDesigner}
                                    />
                                </div>
                            </div>
                            {state.companies.length > 0 &&
                                <div className="col-lg-6">
                                    <div className="contact-group">
                                        <div className="row-1">
                                            Pricing Method
                                        </div>
                                        <div className="row-2">
                                            <Select
                                                className="form-select ml-1"
                                                styles={customStyles}
                                                value={state.pricing}
                                                onChange={(e) => selectPricing(e)}
                                                options={state.pricingMethods}
                                                placeholder="Select Method"
                                                noOptionsMessage={() => null}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                            />
                                            {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <h4 className="website-font mt-4">Server</h4>
                    <div className="row ml-2">
                        <div className="col-lg-6">
                            <div className="contact-group">
                                <div className="row-1">
                                    Name
                                </div>
                                <div className="row-2">
                                    <input className="form-control" placeholder="Enter URL" value={state.name} onChange={(e) => { setState({ ...state, name: e.target.value, urlError: '' }) }} />
                                    {state.nameError ? <label className="eror-fields">{state.nameError}</label> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {state.companies.length > 0 ? (
                                <div className="contact-group">
                                    <div className="row-1">
                                        Company
                                    </div>
                                    <div className="row-2">
                                        <Select
                                            className="form-select ml-1"
                                            styles={customStyles}
                                            value={state.selectedCompany}
                                            onChange={(e) => { setState({ ...state, selectedCompany: e }) }}
                                            options={state.companyOptions}
                                            placeholder="Select Company"
                                            noOptionsMessage={() => null}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                        {state.companyError ? <label className="eror-fields">{state.companyError}</label> : ''}
                                    </div>
                                </div>
                            ) : null }
                        </div>
                    </div>

                    {state.credentials.map((server, index) => (
                        <div key={index}>
                            <div>
                                <h4 className="website-font mt-4">{server.name}</h4>
                                <div className="row ml-2">
                                    <div className="col-lg-6">

                                        {/* Host */}
                                        { server.details.hasOwnProperty('host') ?   //If object has property key = "host"
                                            <div className="contact-group">
                                                <div className="row-1"> Host </div>
                                                <div className="row-2">
                                                    <input className="form-control" placeholder="Enter Host" value={server.details.host || ''} onChange={(e) => updateCredentials(index, 'details', 'host', e.target.value)} />
                                                </div>
                                            </div>
                                        : null }

                                        {/* PEM KEY */}
                                        { server.details.hasOwnProperty('pem') ?   //If object has property key = "pem"
                                            <div className="contact-group">
                                                <div className="row-1">
                                                    Pem Key
                                                </div>
                                                <div className="row-2">
                                                    <input className="form-control" placeholder="Attach file" value={state.pems[index] ? state.pems[index].name : server.details.pem}  onClick={(e) => updateCredentials(index, 'details', 'pem', e.target.value)} />
                                                    {/* <input className="form-control" placeholder="Attach file" value={server.details.pem}  onClick={() => { document.getElementById('add-server-pem').click(); }} /> */}
                                                </div>
                                                <div>
                                                    <img src={attachmentIcon} onClick={() => { document.getElementById('add-server-pem').click(); }} style={{ cursor: "pointer" }} />
                                                </div>
                                            </div>
                                        : null }

                                        {/* Username */}
                                        { server.details.hasOwnProperty('username') ?   //If object has property key = "username"
                                            <div className="contact-group">
                                                <div className="row-1"> Username </div>
                                                <div className="row-2">
                                                    <input className="form-control" placeholder="Enter Username" value={server.details.username || ''} onChange={(e) => updateCredentials(index, 'details', 'username', e.target.value)} />
                                                </div>
                                            </div>
                                        : null }

                                        {/* SSL REQUIRED */}
                                        { server.details.hasOwnProperty('withSsl') ?   //If object has property key = "dbname"
                                            <div className="contact-group contact-group-checkbox">
                                                <div className="row-1">
                                                    SSL Required
                                                </div>
                                                <div className="row-3">
                                                    <div onClick={() => updateCredentials(index, 'details', 'withSsl', 1)} className="yn-option">
                                                        <input name="ssl-required" type="radio" className="form-control" style={{ with: 15, height: 15 }} defaultChecked={server.details.withSsl == 1} checked={server.details.withSsl == 1} /> Yes
                                                    </div>
                                                    <div onClick={() => updateCredentials(index, 'details', 'withSsl', 0)} className="yn-option">
                                                        <input name="ssl-required" type="radio" className="form-control" style={{ with: 15, height: 15 }} defaultChecked={server.details.withSsl == 0} checked={server.details.withSsl == 0} /> No
                                                    </div>
                                                </div>
                                            </div>
                                        : null }
                                    </div>

                                    <div className="col-lg-6">
                                        {/* IP ADDRESS */}
                                        { server.details.hasOwnProperty('ip_address') ?   //If object has property key = "ip_address"
                                            <div className="contact-group">
                                                <div className="row-1">
                                                    IP Address
                                                </div>
                                                <div className="row-2">
                                                    <input className="form-control" placeholder="Enter IP Address" value={server.details.ip_address} onChange={(e) => updateCredentials(index, 'details', 'ip_address', e.target.value)} />
                                                    {
                                                        server.details.ip_address_error ?
                                                            <label className="eror-fields">{server.details.ip_address_error}</label>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        : null }

                                        {/* Port */}
                                        { server.details.hasOwnProperty('port') ?   //If object has property key = "port"
                                            <div className="contact-group">
                                                <div className="row-1"> Port </div>
                                                <div className="row-2">
                                                    <input name="port" counter={index} type="number" min="0" onKeyDown={editPort} className="form-control" placeholder="Enter Port" onChange={(e) => updateCredentials(index, 'details', 'port', e.target.value)} value={server.details.port} />
                                                    {
                                                        server.details.portError ?
                                                            <label className="eror-fields">{server.details.portError}</label>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        : null }

                                        {/* Password */}
                                        { server.details.hasOwnProperty('password') ?   //If object has property key = "password"
                                            <div className="contact-group">
                                                <div className="row-1"> Password </div>
                                                <div className="row-2">
                                                    <input className="form-control" placeholder="Enter Password" value={server.details.password || ''} onChange={(e) => updateCredentials(index, 'details', 'password', e.target.value)} />
                                                </div>
                                            </div>
                                        : null }

                                        {/* DATABASE NAME */}
                                        { server.details.hasOwnProperty('dbname') ?   //If object has property key = "dbname"
                                            <div className="contact-group">
                                                <div className="row-1"> DB Name </div>
                                                <div className="row-2">
                                                    <input className="form-control" placeholder="Enter Database Name" value={server.details.dbname || ''} onChange={(e) => updateCredentials(index, 'details', 'dbname', e.target.value)} />
                                                </div>
                                            </div>
                                        : null }

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="w-400 mt-4"  >
                        <CreateUI
                            items={state.credentials}
                            companiesOptions={state.companiesOptions}
                            handleChange={handleChange}
                            UploadFile={UploadFile}
                            updateCredentials={updateCredentials.bind(this)}
                            credIndex={state.credIndex}
                            credentials={state.credentials[state.credIndex]}
                            editPort={editPort}
                        />
                    </div>

                    <div className="form-action">
                        <button onClick={submitServer} className="btn btn-primary btn-large" disabled={state.isSubmit}>
                            {
                                state.isSubmit ?
                                    (<i className="bx bx-loader bx-spin font-size-14 align-middle mr-2"></i>)
                                    : ''
                            }
                            Save
                        </button>
                    </div>

                </div>
            </div>
        </React.Fragment >
    );
});

export default UpdateServer;
